@import "/src/styles/breakpoints.scss";
@import "/src/styles/variables.scss";

.loadingPage {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dark {
  background-color: $color-black;
}

.neutral {
  background-color: $color-neutral-1;
}
